/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')
const RedirectWrapper = require('./src/components/Various/RedirectWrapper').default
// You can delete this file if you're not using it´
require('./src/styles/typography/syntax.scss')
/*
import 'gatsby-line-highlights-dracula';
/!*
import 'gatsby-syntax-highlights-dracula';
import 'gatsby-code-titles-dracula';
import 'gatsby-code-buttons-dracula';
*!/
*/

exports.wrapPageElement = ({ element, props }) => {
  return (
    <RedirectWrapper {...props}>
      {element}
    </RedirectWrapper>
  )
}
