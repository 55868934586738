import React, { useEffect } from 'react'
import { links } from './redirects.json'
import { navigate } from 'gatsby'

const RedirectWrapper = props => {
  const { children } = props
  const { pathname, hash } = props.location
  
  useEffect(() => {
    const { to } = links.find(link => {
      return link.from === pathname + hash
    }) ?? { to: '' }
    if (to.length > 0) navigate(to)
  }, [props.location.pathname])

  return (
    <>
      {children}
    </>
  )
}

export default RedirectWrapper
